import {emailRegex} from "../../utils/regex";

const registerValidation = (values: any) => {

    let passwordComplexity = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
    const errors: {
        nameOfRole?: string,
        identityCard?: string,
        lastName?: string,
        firstName?: string,
        email?: string,
        phoneNumber?: string,
        password?: string,
        confirmPassword?: string,
        cnp?: string,
        dateOfBirth?: string,
        profileImage?: string,
    } = {};

    if (!values.nameOfRole) {
        errors.nameOfRole = 'Obligatoriu !';
    }
    if (!values.identityCard) {
        errors.identityCard = 'Obligatoriu !';
    }
    if (!values.lastName) {
        errors.lastName = 'Obligatoriu !';
    }
    if (!values.firstName) {
        errors.firstName = 'Obligatoriu !';
    }

    if (!values.email) {
        errors.email = 'Obligatoriu !';
    } else if (!emailRegex(values.email)) {
        errors.email = 'Adresa de mail este invalida !';
    }
    if (!values.phoneNumber) {
        errors.phoneNumber = 'Obligatoriu !';
    }
    if (!values.password) {
        errors.password = 'Obligatoriu !';
    } else if (!passwordComplexity.test(values.password)) {
        errors.password = 'Parola trebuie sa contina cel putin 8 caractere, numar, litera mica, litera mare, caracter special: @ # $ %';
    }

    if (!values.confirmPassword) {
        errors.confirmPassword = 'Obligatoriu !';
    } else if (values.confirmPassword !== values.password) {
        errors.confirmPassword = 'Parolele nu se potrivesc';
    }
    if (!values.cnp) {
        errors.cnp = 'Obligatoriu !';
    }

    if (!values.dateOfBirth) {
        errors.dateOfBirth = 'Obligatoriu !';
    }

    if (!values.profileImage) {
        errors.profileImage = 'Obligatoriu !';
    }

    return errors;
};

export default registerValidation;

export function hideRemoveTidio(user: any) {
    let tidioIFrame = document.getElementById('tidio-chat-code');
    let tidioDiv = document.getElementById('tidio-chat');
    let tidioScript = document.getElementById('tidio_script');

    if (!user ) {
        if (!tidioDiv || !tidioIFrame || !tidioScript) return;
        document.body.removeChild(tidioDiv);
        document.body.removeChild(tidioIFrame);
        document.body.removeChild(tidioScript);
        return;
    }

    if (user?.roles[0] !== 'ROLE_MANAGER'){
        if (!tidioDiv || !tidioIFrame || !tidioScript) return;
        tidioDiv.style.display = 'none';
        tidioIFrame.style.display = 'none';
        return;
    }
}

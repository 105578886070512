import {Avatar} from "@mui/material";
import React from "react";

const AvatarHeader = () => {
    return(
        <Avatar alt={'profile-image'} src={'../../../../profile/avatar_victory.svg'} />
    )
}

export default AvatarHeader;

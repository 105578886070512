import {createSlice} from "@reduxjs/toolkit";

interface DisciplinaryState {
    isUpdated: boolean,
    isAdded: boolean,
}

const initialState: DisciplinaryState = {
    isUpdated: false,
    isAdded: false,
}

export const DisciplinarySlice = createSlice({
    name: 'disciplinary',
    initialState,
    reducers: {
        updateDisciplinary: (state, action) => {
            state.isUpdated = action.payload;
        },

        addDisciplinary: (state, action) => {
            state.isAdded = action.payload;
        }
    }
});

export const {updateDisciplinary, addDisciplinary} = DisciplinarySlice.actions;
export default DisciplinarySlice.reducer;

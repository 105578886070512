import React, {ReactNode, useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import {administrationAccount} from '../../menu';
import useDarkMode from '../../hooks/useDarkMode';
import Collapse from '../../components/bootstrap/Collapse';
import {NavigationLine} from '../Navigation/Navigation';
import Icon from '../../components/icon/Icon';
import useNavigationItemHandle from '../../hooks/useNavigationItemHandle';
import AuthContext from '../../contexts/authContext';
import {DropdownItem, DropdownMenu} from "../../components/bootstrap/Dropdown";
import Button from "../../components/bootstrap/Button";
import {ICProfileInfo} from "../../utils/interfaces/ICProfileInfo";
import jwtInstance from "../../guards/jwt.interceptor";
import {API_USER} from "../../utils/restApi";
import {errorResponse} from "../../utils/getResponse";
import {Avatar} from "@mui/material";
import {getNameOfRoleInRo} from "../../utils/displayRole";
import AvatarHeader from "../../components/AvatarHeader";
import FontAwesomeIcon from "../../components/icon/FontAwesomeIcon";

interface ICUser {
    isDefaultHeader: boolean,
}

const User = ({isDefaultHeader}: ICUser) => {

    const [collapseStatus, setCollapseStatus] = useState<boolean>(false);
    const [profileInfo, setProfileInfo] = useState<ICProfileInfo>();
    const navigate = useNavigate();
    const handleItem = useNavigationItemHandle();
    const {darkModeStatus, setDarkModeStatus} = useDarkMode();
    const {logout} = useContext(AuthContext);
    const user = JSON.parse(`${localStorage.getItem('user')}`);
    const {t} = useTranslation(['translation', 'menu']);

    useEffect(() => {
        jwtInstance.get(API_USER.contactInfo, {params: {uuid: user?.uuid}})
            .then(response => {
                    if (response.status === 200) {
                        setProfileInfo(response.data);
                    }
                }
            ).catch(error => {
            errorResponse(error, 'Eroare', 'danger');
        })
    }, [])

    return (
        <>
            <div
                className={classNames(` ${!isDefaultHeader && 'user'} ${isDefaultHeader && `bg-transparent m-0 ${darkModeStatus ? 'text-light' : 'text-dark'}`}`, {open: collapseStatus})}
                role='presentation'
                onClick={() => setCollapseStatus(!collapseStatus)}>
                {!isDefaultHeader && (<div className={'user-avatar'}>
                    <img
                        src={profileInfo?.profileImage}
                        alt='Avatar'
                        width={128}
                        height={128}
                    />
                </div>)}

                {isDefaultHeader && (<AvatarHeader />)}

                {!isDefaultHeader && (<div className='user-info'>
                    <div className='user-name d-flex align-items-center'>
                        {`${profileInfo?.fullName}`}
                        <Icon icon='Verified' className='ms-1' color='info'/>
                    </div>
                    <div className='user-sub-title'>{getNameOfRoleInRo(profileInfo?.nameOfRole)}</div>
                </div>)}
            </div>

            <DropdownMenu>
                <DropdownItem>
                    <Button
                        icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
                        onClick={() => setDarkModeStatus(!darkModeStatus)}
                        aria-label='Toggle fullscreen'>
                        {darkModeStatus ? 'Mod intunecat' : 'Mod luminat'}
                    </Button>
                </DropdownItem>
            </DropdownMenu>

            {!isDefaultHeader && (<Collapse isOpen={collapseStatus} className='user-menu'>
                <nav aria-label='aside-bottom-user-menu'>
                    <div className='navigation'>
                        <div
                            role='presentation'
                            className='navigation-item cursor-pointer'
                            onClick={() =>
                                navigate(`${administrationAccount.account.path}`)
                            }>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<FontAwesomeIcon iconName={'fa-solid fa-user-gear'} isMenu={true}/>
									<span className='navigation-text'>
										Contul tau
									</span>
								</span>
							</span>
                        </div>
                        <div
                            role='presentation'
                            className='navigation-item cursor-pointer'
                            onClick={() => {
                                setDarkModeStatus(!darkModeStatus);
                                handleItem();
                            }}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon
                                        icon={darkModeStatus ? 'LightMode' : 'DarkMode'}
                                        color={darkModeStatus ? 'warning' : 'info'}
                                        className='navigation-icon'
                                    />
									<span className='navigation-text'>
										{darkModeStatus
                                            ? ('Mod luminat')
                                            : ('Mod intunecat')}
									</span>
								</span>
							</span>
                        </div>
                    </div>
                </nav>
                <NavigationLine/>
                <nav aria-label='aside-bottom-user-menu-2'>
                    <div className='navigation'>
                        <div
                            role='presentation'
                            className='navigation-item cursor-pointer'
                            onClick={() => {
                                logout()
                            }}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<FontAwesomeIcon iconName={'fa-solid fa-arrow-right-from-bracket'} isMenu={true}/>
									<span className='navigation-text'>
										{'Iesire din aplicatie'}
									</span>
								</span>
							</span>
                        </div>
                    </div>
                </nav>
            </Collapse>)}

        </>
    );
};

export default User;

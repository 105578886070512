import { createSlice } from "@reduxjs/toolkit"

interface EditionState {
    isUpdated: boolean,
    isAdded: boolean,
    editionUuid: string,
}

const initialState: EditionState = {
    isUpdated: false,
    isAdded: false,
    editionUuid: '',
}

export const EditionSlice = createSlice({
    name: 'edition',
    initialState,
    reducers: {
        updateEdition: (state, action) => {
            state.isUpdated = action.payload
        },
        addEdition: (state, action) => {
            state.isAdded = action.payload
        },
        getEditionUuid: (state, action) => {
            state.editionUuid = action.payload;
        }
    }
});

export const {updateEdition, addEdition, getEditionUuid} = EditionSlice.actions;
export default EditionSlice.reducer;

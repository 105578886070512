import React, { FC } from 'react';
import PropTypes from 'prop-types';

interface ILogoProps {
	width?: number;
	height?: number;
	sideNavLogo?: string;
}
const Logo: FC<ILogoProps> = ({ width, height, sideNavLogo }) => {
	return (
		<img width={width} height={height} src={sideNavLogo ? sideNavLogo : '/logo.svg'} alt={'logo'}/>
	);
};
Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
Logo.defaultProps = {
	width: 2155,
	height: 854,
};

export default Logo;

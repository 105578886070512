import React, {FC, useCallback, useState} from "react";
import classNames from "classnames";

import {Link, useSearchParams} from "react-router-dom";
import {demoPages} from "../../../menu";

import ResetPassword from "./ResetPassword";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import Page from "../../../layout/Page/Page";
import Card, {CardBody, CardHeader} from "../../../components/bootstrap/Card";
import Logo from "../../../components/Logo";
import Button from "../../../components/bootstrap/Button";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";

import jwtInstance from "../../../guards/jwt.interceptor";
import {API_AUTH} from "../../../utils/restApi";
import {errorResponse, successResponse} from "../../../utils/getResponse";




const ForgottenPassword: FC<any> = () => {

    const user = JSON.parse(`${localStorage.getItem('user')}`);
    const [email, setEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSecondStep, setIsSecondStep] = useState(false);
    const [searchParams] = useSearchParams();


    const handleEmail = useCallback((event: any) => {
        setEmail(event.target.value);
    }, [email]);


    const resetPassword = () => {
        setIsSubmitting(true);
        jwtInstance.get(API_AUTH.resetPassword, {params: {email: email, sessionID: searchParams.get('sessionID')}})
            .then(response => {
                if (response.status === 200) {
                    successResponse(response, 'Resetare parola', 'default');
                    setEmail('');
                    setIsSubmitting(false);
                    setIsSecondStep(true);
                }
            }).catch(error => {
                errorResponse(error, 'Eroare resetare parola', 'danger')
        })
    };

    return (
        <>
            {!user?.token && (<PageWrapper isProtected={false} title={'Resetare parola'} background={true}
                                    className={classNames({'bg-success': true})}>
                <Page className={'p-0'}>
                    <div className='row h-100 align-items-center justify-content-center'>
                        <div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
                            <Card className={'shadow-3d-dark'} data-tour={'login-player-page'}>
                                <CardHeader className={'justify-content-end'}>
                                    <Link to={`/${demoPages.login.path}`}>
                                        <Button
                                            icon={'ArrowBackIos'}
                                            isLight={false}>
                                            {'Inapoi'}
                                        </Button>
                                    </Link>
                                </CardHeader>
                                <CardBody>
                                    <div className='text-center my-2'>
                                        <Logo width={400} height={150}/>
                                    </div>
                                    <div
                                        className={classNames('rounded-3', {
                                            'bg-l10-dark': true,
                                            'bg-dark': false,
                                        })}>
                                        <div className='row row-cols-2 g-3 pb-3 px-3 mt-0 justify-content-center'>
                                            <div className='col-12'>
                                                <Button
                                                    color={'dark'}
                                                    icon={'Apps'}
                                                    isLight={false}
                                                    style={{cursor: 'initial'}}
                                                    className='rounded-1 w-100'
                                                    size='lg'>
                                                    {'Resetare parola'}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                    <>
                                        <div className='text-center h6 text-muted mb-3 mt-5'>{!isSecondStep ? 'Introduceti adresa de email!' : 'Introduceti datele pentru resetare'}</div>
                                    </>


                                    {!isSecondStep && (
                                        <>
                                            <div className={'row g-4 mb-3'}>
                                                <div className={'col-12'}>
                                                    <FormGroup
                                                        id='email'
                                                        isFloating
                                                        label='Email'>

                                                        <input
                                                            type={'text'}
                                                            autoFocus={false}
                                                            className={'form-control'}
                                                            value={email}
                                                            onChange={(event: any) => handleEmail(event)}
                                                        />
                                                    </FormGroup>
                                                </div>
                                            </div>

                                            <div className={'row justify-content-center'}>
                                                <div className={'col-xs-4 col-md-6 d-flex justify-content-center'}>
                                                    <Button
                                                        color='danger'
                                                        size={'sm'}
                                                        className='py-2'
                                                        icon={'Send'}
                                                        onClick={() => resetPassword()}
                                                    >
                                                        {isSubmitting ? 'Se trimite...' : 'Trimite'}
                                                    </Button>
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    {isSecondStep && <ResetPassword />}

                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </Page>
            </PageWrapper>)}
        </>
    );
};


export default ForgottenPassword;

import {createSlice} from "@reduxjs/toolkit";


interface GeneralSettingsState {
    isUpdated: boolean,
    isAdded: boolean,
}

const initialState: GeneralSettingsState = {
    isUpdated: false,
    isAdded: false,
}

export const GeneralSettingsSlice = createSlice({
    name: 'generalSettings',
    initialState,
    reducers: {
        updateGeneralSettings: (state, action) => {
            state.isUpdated = action.payload
        },
        addGeneralSettings: (state, action) => {
            state.isAdded = action.payload
        }
    }
});

export const {addGeneralSettings, updateGeneralSettings} = GeneralSettingsSlice.actions;
export default GeneralSettingsSlice.reducer;

import {createSlice} from "@reduxjs/toolkit";


interface CompetitionState {
    isUpdated: boolean,
    isAdded: boolean,
    competitionUuid: string
}

const initialState: CompetitionState = {
    isUpdated: false,
    isAdded: false,
    competitionUuid: ''
}

export const CompetitionSlice = createSlice({
    name: 'competition',
    initialState,
    reducers: {
        updateCompetition: (state, action) => {
            state.isUpdated = action.payload
        },
        addCompetition: (state, action) => {
            state.isAdded = action.payload
        },
        setCompetitionUuid:(state, action) => {
            state.competitionUuid = action.payload
        }
    }
});

export const {updateCompetition, addCompetition, setCompetitionUuid} = CompetitionSlice.actions;
export default CompetitionSlice.reducer;

import React from 'react';
import { demoPages, administrationSite } from '../menu';
import DefaultFooter from '../pages/_layout/_footers/DefaultFooter';

const footers = [
	{ path: demoPages.login.path, element: null, exact: true },
	{ path: demoPages.register.path, element: null, exact: true },
	{ path: demoPages.resetPassword.path, element: null, exact: true },
	{ path: demoPages.verifyAccount.path, element: null, exact: true },
	{ path: demoPages.page404.path, element: null, exact: true },
	{ path: '*', element: <DefaultFooter /> },
];

export default footers;

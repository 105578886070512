import * as Yup from "yup";

export const ResetPasswordSchema =
    Yup.object().shape({
        sixDigitCode: Yup.string().required('Codul de pe mail este necesar !'),
        password: Yup.string().required('Parola este necesara !'),
        confirmPassword: Yup.string()
            .required('Confirmare parola')
            .oneOf([Yup.ref('password'), null], 'Parolele nu se potrivesc !'),
    });

export const defaultValues = {
    sixDigitCode: '',
    password: '',
    confirmPassword: '',
};




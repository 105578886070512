import {createSlice} from "@reduxjs/toolkit";


interface TeamState {
    isUpdated: boolean,
    isAdded: boolean,
    teams: any,
}

const initialState: TeamState = {
    isUpdated: false,
    isAdded: false,
    teams: [],
}

export const TeamSlice = createSlice({
    name: 'team',
    initialState,
    reducers: {
        updateTeam: (state, action) => {
            state.isUpdated = action.payload;
        },

        addTeam: (state, action) => {
            state.isAdded = action.payload;
        },
        getTeams: (state, action) => {
            state.teams = action.payload;
        }
    }
});

export const {updateTeam, addTeam, getTeams} = TeamSlice.actions;
export default TeamSlice.reducer;

import {createSlice} from "@reduxjs/toolkit";

interface TransferState {
    isAdded: boolean,
    isUpdated: boolean,
}

const initialState: TransferState = {
    isAdded: false,
    isUpdated: false,
}

export const TransferSlice = createSlice({
    name: 'transfer',
    initialState,
    reducers: {
        addTransfer: (state, action) => {
            state.isAdded = action.payload;
        },
        updateTransfer: (state, action) => {
            state.isUpdated = action.payload;
        }
    }
});

export const {addTransfer, updateTransfer} = TransferSlice.actions;
export default TransferSlice.reducer;


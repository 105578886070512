import { createSlice, PayloadAction} from "@reduxjs/toolkit";


interface UserState {
    isUpdated: boolean,
}

const initialState: UserState = {
    isUpdated: false,
}

export const UserSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        updateUser: (state, action) => {
            state.isUpdated = action.payload
        },
    }
})

export const { updateUser} = UserSlice.actions;

export default UserSlice.reducer;

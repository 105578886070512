import PropTypes from 'prop-types';
// form
import { FormProvider} from 'react-hook-form';
import {FC, ReactNode} from "react";
import classNames from "classnames";

interface IFormComponent {
    children?: ReactNode,
    onSubmit?: any,
    methods?: any,
    className?: string,
}


const FormComponent: FC<IFormComponent> = ({children, onSubmit, methods, className}) => {
  return(
      <FormProvider {...methods}>
          <form onSubmit={onSubmit} className={classNames('row', 'g-4', className)}>
              {children}
          </form>
      </FormProvider>
  );
};

FormComponent.propTypes = {
    children: PropTypes.node.isRequired,
    methods: PropTypes.object.isRequired,
    onSubmit: PropTypes.func,
    className: PropTypes.string,
};

export default FormComponent;

import UserImage from '../../assets/img/typeAccounts/manager.png';
import UserImageWebp from '../../assets/img/typeAccounts/manager.png';

import UserImage2 from '../../assets/img/typeAccounts/delegat.png';
import UserImage2Webp from '../../assets/img/typeAccounts/delegat.png';

import UserImage4 from '../../assets/img/typeAccounts/jucator.png';
import UserImage4Webp from '../../assets/img/typeAccounts/jucator.png';

export interface ITypeAccountProps {
    id: string,
    typeAccount: string,
    valueType: string,
    src: string,
    srcSet: string,
    activeSelected: boolean,
    selected: boolean,
}

const manager: ITypeAccountProps = {
    id: 'manager',
    typeAccount: 'Manager',
    valueType: 'ROLE_MANAGER',
    src: UserImage,
    srcSet: UserImageWebp,
    activeSelected: true,
    selected: true,
};

const delegate: ITypeAccountProps = {
    id: 'delegate',
    typeAccount: 'Delegat',
    valueType: 'ROLE_DELEGATE',
    src: UserImage2,
    srcSet: UserImage2Webp,
    activeSelected: false,
    selected: false,
};

const player: ITypeAccountProps = {
    id: 'player',
    typeAccount: 'Jucator',
    valueType: 'ROLE_PLAYER',
    src: UserImage4,
    srcSet: UserImage4Webp,
    activeSelected: false,
    selected: false,
};

const TYPE_ACCOUNTS: ITypeAccountProps[] = [manager, delegate, player];
export default TYPE_ACCOUNTS;


import {configureStore} from "@reduxjs/toolkit";
import userSlice from './slices/user';
import teamSlice from './slices/team';
import competitionSlice from './slices/competition';
import invitationSlice from './slices/invitation';
import editionSlice from './slices/edition';
import stages from "./slices/stages";
import leagueSlice from './slices/leagues';
import generalSettings from "./slices/generalSettings";
import financial from './slices/financial';
import match from './slices/match';
import transfer from './slices/transfer';
import cup from "./slices/cup";
import disciplinary from './slices/disciplinary';
import media from './slices/media';
import award from './slices/award';
import faq from './slices/faq';
import {persistReducer, persistStore} from 'redux-persist'
import thunk from 'redux-thunk'
import storage from "redux-persist/lib/storage";
import {useDispatch as useAppDispatch, useSelector as useAppSelector} from 'react-redux';


const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducerUser = persistReducer(persistConfig, userSlice);
const persistedReducerTeam = persistReducer(persistConfig, teamSlice);
const persistedReducerCompetition = persistReducer(persistConfig, competitionSlice);
const persistedReducerInvitation = persistReducer(persistConfig, invitationSlice);
const persistedReducerEdition = persistReducer(persistConfig, editionSlice);
const persistedReducerLeague = persistReducer(persistConfig, leagueSlice);
const persistedReducerGeneralSettings = persistReducer(persistConfig, generalSettings);
const persistedFinancial = persistReducer(persistConfig, financial);
const persistedReducerStages = persistReducer(persistConfig, stages);
const persistedReducerMatch = persistReducer(persistConfig, match);
const persistedReducerCup = persistReducer(persistConfig, cup);
const persistedReducerTransfer = persistReducer(persistConfig, transfer);
const persistedReducerDisciplinary = persistReducer(persistConfig, disciplinary);
const persistedReducerMedia = persistReducer(persistConfig, media);
const persistedReducerAward = persistReducer(persistConfig, award);
const persistedReducerFaq = persistReducer(persistConfig, faq);

const store = configureStore({
    reducer: {
        user: persistedReducerUser,
        team: persistedReducerTeam,
        competition: persistedReducerCompetition,
        invitation: persistedReducerInvitation,
        edition: persistedReducerEdition,
        league: persistedReducerLeague,
        generalSettings: persistedReducerGeneralSettings,
        financial: persistedFinancial,
        stages: persistedReducerStages,
        match: persistedReducerMatch,
        cup: persistedReducerCup,
        transfer: persistedReducerTransfer,
        disciplinary: persistedReducerDisciplinary,
        media: persistedReducerMedia,
        award: persistedReducerAward,
        faq: persistedReducerFaq,
    },
    middleware: [thunk]
})

const persistor = persistStore(store)
const {dispatch} = store;

const useSelector = useAppSelector;
export type RootState = ReturnType<typeof store.getState>
const useDispatch = () => useAppDispatch()

export {store, persistor, dispatch, useSelector, useDispatch};

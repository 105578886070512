import {createSlice} from "@reduxjs/toolkit";

interface MatchState {
    isLeague: boolean,
    isAddedMatch: boolean,
    isUpdated: boolean,
    lineupAdded: boolean,
    selectedMatch: any,
}

const initialState: MatchState = {
    isLeague: false,
    isAddedMatch: false,
    isUpdated: false,
    lineupAdded: false,
    selectedMatch: null,
};

export const MatchSlice = createSlice({
    name: 'match',
    initialState,
    reducers: {
        updateFilterLeague: (state, action) => {
            state.isLeague = action.payload;
        },
        addMatch: (state, action) => {
          state.isAddedMatch = action.payload;
        },
        updateMatch: (state, action) => {
            state.isUpdated = action.payload;
        },
        addLineup: (state, action) => {
            state.lineupAdded = action.payload;
        },
        selectMatch: (state, action) => {
            state.selectedMatch = action.payload;
        }
    }
});

export const {updateFilterLeague, addMatch, updateMatch, addLineup, selectMatch} = MatchSlice.actions;
export default MatchSlice.reducer;

import React, {forwardRef, ReactElement, useLayoutEffect} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {ISubHeaderProps} from '../SubHeader/SubHeader';
import {IPageProps} from '../Page/Page';

interface IPageWrapperProps {
    isProtected?: boolean;
    title?: string;
    description?: string;
    children:
        | ReactElement<ISubHeaderProps>[]
        | ReactElement<IPageProps>
        | ReactElement<IPageProps>[];
    className?: string;
    background?: boolean,
    isVerify?: boolean,
}

const PageWrapper = forwardRef<HTMLDivElement, IPageWrapperProps>(
    ({isProtected, title, description, className, children, background, isVerify}, ref) => {
        useLayoutEffect(() => {
            // @ts-ignore
            document.getElementsByTagName('TITLE')[0].text = `${title ? `${title} | ` : ''}${
                'Victory Cup'
            }`;
            // @ts-ignore
            document
                ?.querySelector('meta[name="description"]')
                .setAttribute('content', description || process.env.REACT_APP_META_DESC || '');
        });

        return (
            <div ref={ref}
                 className={classNames('page-wrapper', 'container-fluid', className,)}
                 style={{
                     background: `${background ? 'url(/background/login-register.png)' : isVerify ? 'url(/background/verify-account.webp)' : ''}`,
                     backgroundSize: 'cover'
                 }}>
                {children}
            </div>
        );
    },
);
PageWrapper.displayName = 'PageWrapper';
PageWrapper.propTypes = {
    isProtected: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    // @ts-ignore
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    background: PropTypes.bool,
};
PageWrapper.defaultProps = {
    isProtected: true,
    title: undefined,
    description: undefined,
    className: undefined,
    background: false,
    isVerify: false,
};

export default PageWrapper;

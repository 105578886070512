import React, {FC} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {ErrorMessage} from "@hookform/error-message";

import {useNavigate} from "react-router-dom";
import {demoPages} from "../../../menu";

import FormComponent from "../../../components/hook-form/FormComponent";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Button from "../../../components/bootstrap/Button";

import {ResetPasswordSchema, defaultValues} from "../../../utils/objects-form/resetPassword";
import jwtInstance from "../../../guards/jwt.interceptor";
import {API_AUTH} from "../../../utils/restApi";
import {errorResponse, successResponse} from "../../../utils/getResponse";




const ResetPassword: FC<any> = () => {

    const navigate = useNavigate();
    const methods = useForm({
        resolver: yupResolver(ResetPasswordSchema),
        defaultValues
    });

    const {
        register,
        handleSubmit,
        formState: {errors, isSubmitting}
    } = methods;

    const onSubmit = async (data: any) => {
        jwtInstance.post(API_AUTH.forgottenPassword, data)
            .then(response => {
                if (response.status === 200) {
                    successResponse(response, 'Resetare parola', 'default');
                    navigate({
                        pathname: `/${demoPages.login.path}`,
                    })
                }
            }).catch(error => {
            errorResponse(error, 'Eroare resetare parola', 'danger');
        })
    }


    return(
        <FormComponent methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <div className={'row g-4 mb-3'}>
                <div className={'col-12'}>
                    <FormGroup
                        id='sixDigitsCode'
                        isFloating
                        label='Codul primit pe email'>

                        <input
                            type={'text'}
                            style={{textTransform: 'uppercase'}}
                            autoFocus={false}
                            className={'form-control'}
                            {...register('sixDigitCode')}

                        />
                    </FormGroup>
                    <ErrorMessage
                        errors={errors}
                        name="sixDigitCode"
                        render={({message}) => <p className={'text-danger'}>{message}</p>}
                    />
                </div>

                <div className={'col-12'}>
                    <FormGroup
                        id='newPassword'
                        isFloating
                        label='Parola noua'>

                        <input
                            type={'password'}
                            autoFocus={false}
                            className={'form-control'}
                            {...register('password')}
                        />
                    </FormGroup>
                    <ErrorMessage
                        errors={errors}
                        name="password"
                        render={({message}) => <p className={'text-danger'}>{message}</p>}
                    />

                </div>


                <div className={'col-12'}>
                    <FormGroup
                        id='confirmNewPassword'
                        isFloating
                        label='Confirmare parola'>

                        <input
                            type={'password'}
                            autoFocus={false}
                            className={'form-control'}
                            {...register('confirmPassword')}
                        />
                    </FormGroup>
                    <ErrorMessage
                        errors={errors}
                        name="confirmPassword"
                        render={({message}) => <p className={'text-danger'}>{message}</p>}
                    />
                </div>
            </div>
            <div className={'row g-4 justify-content-center'}>
                <div className={'col-xs-4 col-md-6 d-flex justify-content-center'}>
                    <Button
                        color='danger'
                        size={'sm'}
                        className='py-2'
                        icon={'Send'}
                        type={'submit'}
                    >
                        {isSubmitting ? 'Se reseteaza...' : 'Reseteaza'}
                    </Button>
                </div>
            </div>
        </FormComponent>
    )
}

export default ResetPassword;

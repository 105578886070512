import React from 'react';
import {administrationSite, demoPages} from '../menu';
import DefaultHeader from '../pages/_layout/_headers/DefaultHeader';

const headers = [
    {path: demoPages.login.path, element: null, exact: true},
    {path: demoPages.register.path, element: null, exact: true},
    {path: demoPages.resetPassword.path, element: null, exact: true},
    {path: demoPages.verifyAccount.path, element: null, exact: true},
    {path: demoPages.page404.path, element: null, exact: true},
    {
        path: `*`,
        element: <DefaultHeader/>,
    },
];

export default headers;

import { createSlice } from "@reduxjs/toolkit";

interface LeaguesState{
    isUpdated: boolean,
    isAdded: boolean,
    isSequenceTeamsLoaded: boolean,
    leagueLogo: string,
}

const initialState: LeaguesState = {
    isUpdated: false,
    isAdded: false,
    isSequenceTeamsLoaded: false,
    leagueLogo: '',
}

export const LeagueSlice = createSlice({
    name: 'league',
    initialState,
    reducers: {
        updateLeague: (state, action) => {
            state.isUpdated = action.payload;
        },
        addLeague: (state, action) => {
            state.isAdded = action.payload;
        },
        loadSequenceTeams:(state, action) => {
            state.isSequenceTeamsLoaded = action.payload;
        },
        getLeagueLogo: (state, action) => {
            state.leagueLogo = action.payload;
        }
    }
});

export const {updateLeague,addLeague, loadSequenceTeams, getLeagueLogo} = LeagueSlice.actions;
export default LeagueSlice.reducer;

import {createSlice} from "@reduxjs/toolkit";

interface StagesState {
    isCanceled: boolean,
    isAdded: boolean
}

const initialState: StagesState = {
    isCanceled: false,
    isAdded: false
}

export const StagesSlice = createSlice({
    name: 'stages',
    initialState,
    reducers: {
        cancelStages: (state, action) => {
            state.isCanceled = action.payload
        },
        addStages: (state, action) => {
            state.isAdded = action.payload
        }
    }
});

export const {addStages, cancelStages} = StagesSlice.actions;
export default StagesSlice.reducer;

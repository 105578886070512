import {createSlice} from "@reduxjs/toolkit";

interface FinancialState {
    isUpdated: boolean,
    isAdded: boolean,
}

const initialState: FinancialState = {
    isUpdated: false,
    isAdded: false,
}

export const FinancialSlice = createSlice({
    name: 'financial',
    initialState,
    reducers: {
        updateFinancial: (state, action) => {
            state.isUpdated = action.payload
        },
        addFinancial: (state, action) => {
            state.isAdded = action.payload
        }
    }
});

export const {updateFinancial, addFinancial} = FinancialSlice.actions;
export default FinancialSlice.reducer;

import React from 'react';
import Footer from '../../../layout/Footer/Footer';
import classNames from "classnames";
import useDarkMode from "../../../hooks/useDarkMode";

const DefaultFooter = () => {
    const {darkModeStatus} = useDarkMode();
    // @ts-ignore
    const user = JSON.parse(localStorage.getItem('user'));
    const localYear = new Date().getFullYear()

    return (
        <>
            {user?.token && (<Footer>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col'>
                            <span className='fw-bold' style={{fontSize: 10}}>{`Copyright © ${localYear} - Version 1.2.2`}</span>
                        </div>
                        <div className='col-auto'>
                            <a
                                href='https://victorycup.ro'
                                target={'_blank'}
                                className={classNames('text-decoration-none', {
                                    'link-dark': !darkModeStatus,
                                    'link-light': darkModeStatus,
                                })}>
                                <small className='fw-bold'>Victory Cup</small>
                            </a>
                        </div>
                    </div>
                </div>
            </Footer>)}
        </>
    );
};

export default DefaultFooter;

import React, {FC, useContext} from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {yupResolver} from "@hookform/resolvers/yup";
import {ErrorMessage} from "@hookform/error-message";
import AuthContext from "../../../contexts/authContext";

import {Link, useNavigate} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {demoPages} from "../../../menu";

import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, {CardBody, CardFooter} from '../../../components/bootstrap/Card';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import FormComponent from "../../../components/hook-form/FormComponent";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Spinner from "../../../components/bootstrap/Spinner";

import {v4 as uuidv4} from 'uuid'


interface ILoginHeaderProps {
    isNewUser?: boolean;
}

export const LoginHeader: FC<ILoginHeaderProps> = () => {

    return (
        <>
            <div className='text-center h4 fw-bold mt-5'>Access aplicatie</div>
            <div className='text-center h6 text-muted mb-5'>Introduceti credentialele!</div>
        </>
    );
};

interface ILoginProps {
    isSignUp?: boolean;
}

const Login: FC<ILoginProps> = ({isSignUp}) => {

    const user = JSON.parse(`${localStorage.getItem('user')}`);
    const {login, isLoading} = useContext(AuthContext);
    const navigate = useNavigate();
    const generateUUID: string = uuidv4();
    const goToResetPassword = () => {
        navigate({
            pathname: `/${demoPages.resetPassword.path}`,
            search: `?sessionID=${generateUUID}`
        })
    };

    const LoginSchema = Yup.object().shape({
        email: Yup.string().email('Adresa de email nu este valida').required('Introduceti adresa de email'),
        password: Yup.string().required('Introduceti parola'),
    });

    const defaultValues = {
        email: '',
        password: '',
    };

    const methods = useForm({
        resolver: yupResolver(LoginSchema),
        defaultValues,
    });

    const {
        register,
        handleSubmit,
        formState: {errors},
    } = methods;

    const onSubmit = (data: any) => {
        login(data);
    };

    return (
        <>
            {!user?.token && (<PageWrapper
                isProtected={false}
                title={'Autentificare'}
                background={true}
                className={classNames({'bg-danger': true,})}>
                <Page className='p-0'>
                    <div className='row h-100 align-items-center justify-content-center'>
                        <div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>

                            <Card className='shadow-3d-dark' data-tour='login-page'>
                                <CardBody>
                                    <div className='text-center my-2'>
                                        <Logo width={400} height={150}/>
                                    </div>
                                    <div
                                        className={classNames('rounded-3 bg-l10-dark',)}>
                                        <div className='row row-cols-2 g-3 pb-3 px-3 mt-0'>
                                            <div className='col'>
                                                <Button
                                                    color={'dark'}
                                                    isLight={false}
                                                    className='rounded-1 w-100'
                                                    size='lg'>
                                                    {'Autentificare'}
                                                </Button>
                                            </div>
                                            <div className='col'>
                                                <Link to={'/auth-pages/register'}>
                                                    <Button
                                                        color={'dark'}
                                                        isLight={true}
                                                        icon={'AppRegistration'}
                                                        className='rounded-1 w-100'
                                                        size='lg'>
                                                        {'Inregistrare'}
                                                    </Button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>

                                    <LoginHeader/>
                                    <FormComponent methods={methods} onSubmit={handleSubmit(onSubmit)}>
                                        <div className={'col-12'}>
                                            <div className='col-12'>
                                                <FormGroup
                                                    id='email'
                                                    isFloating
                                                    className={'mb-2'}
                                                    label='Adresa de email'>

                                                    <input
                                                        type={'text'} className={'form-control'}
                                                        autoFocus={false} {...register('email')}
                                                    />
                                                </FormGroup>
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="email"
                                                    render={({message}) => <p className={'text-danger'}>{message}</p>}
                                                />

                                                <FormGroup
                                                    id='password'
                                                    isFloating
                                                    label='Parola'>

                                                    <input
                                                        type={'password'}
                                                        autoFocus={false}
                                                        className={'form-control'} {...register('password')}
                                                    />
                                                </FormGroup>
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="password"
                                                    render={({message}) => <p className={'text-danger'}>{message}</p>}
                                                />

                                            </div>

                                        </div>
                                        <div className='col-12'>
                                            <Button
                                                color='dark'
                                                className='w-100 py-3'
                                                type={'submit'}>
                                                {isLoading && (
                                                    <Spinner color={'light'} isSmall inButton isGrow/>
                                                )}
                                                {'Intra in cont'}
                                            </Button>
                                        </div>
                                    </FormComponent>

                                </CardBody>
                                <CardFooter className={'flex-column align-items-md-end align-items-end py-0 mb-1'}>
                                    <Button icon={'Security'} color={'light'} onClick={() => goToResetPassword()}>
                                        {'Reseteaza parola'}
                                    </Button>
                                </CardFooter>
                            </Card>
                        </div>
                    </div>
                </Page>
            </PageWrapper>)}
        </>
    );
};
Login.propTypes = {
    isSignUp: PropTypes.bool,
};
Login.defaultProps = {
    isSignUp: false,
};

export default Login;

import {useFormik} from "formik";
import FormGroup from "../bootstrap/forms/FormGroup";
import Input from "../bootstrap/forms/Input";
import React, {useEffect, useState} from "react";
import TYPE_ACCOUNTS from "../../common/data/typeAccount";
import {useNavigate, useSearchParams} from "react-router-dom";
import Card, {CardBody, CardFooter} from "../bootstrap/Card";
import Button from "../bootstrap/Button";
import Spinner from "../bootstrap/Spinner";
import {Avatar} from "@mui/material";
import IdentityCard from "./identityCard/IdentityCard";
import registerValidation from "../../validator/auth/registerValidation";
import jwtInstance from "../../guards/jwt.interceptor";
import {API_AUTH} from "../../utils/restApi";
import {errorResponse, successResponse} from "../../utils/getResponse";
import {demoPages} from "../../menu";
import ReactImageUploading, {ImageListType} from "react-images-uploading";


const RegisterForm = () => {

    const [selectedAccountType, setSelectedAccountType] = useState<string>('');
    const [images, setImages] = useState<any>([]);
    const [imagesCI, setImagesCI] = useState<any>([]);
    const [searchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            nameOfRole: '',
            identityCard: '',
            lastName: '',
            firstName: '',
            email: searchParams.get('email') || '',
            phoneNumber: '',
            password: '',
            confirmPassword: '',
            cnp: '',
            dateOfBirth: '',
            profileImage: '',
        },
        validate: (values) => registerValidation(values),
        onSubmit: (values) => {
            setIsLoading(true);
            jwtInstance.post(API_AUTH.register, values, {params: {invitationCode: searchParams.get('invitationCode')}})
                .then(response => {
                    if (response.status === 200) {
                        setIsLoading(false);
                        successResponse(response, 'Inregistrare cont', 'success');
                        navigate({
                            pathname: `/${demoPages.verifyAccount.path}`,
                        });
                    }
                }).catch(error => {
                setIsLoading(false);
                formik.setSubmitting(false);
                errorResponse(error, 'Eroare inregistrare', 'danger');
            })
        }
    });


    const uploadImage = (imageList: ImageListType, addUpdateIndex: number[] | undefined) => {
        setImages(imageList as never[]);

    };

    const uploadCI = (imageList: ImageListType, addUpdateIndex: number[] | undefined) => {
        setImagesCI(imageList as never[]);

    };


    useEffect(() => {
        if (images.length > 0) {
            formik.setFieldValue('profileImage', images[0]?.dataURL);
        }

        if (imagesCI.length > 0) {
            formik.setFieldValue('identityCard', imagesCI[0]?.dataURL);
        }

    }, [images, imagesCI]);


    const handleInput = (value: string) => {
        setSelectedAccountType(value);
        formik.setFieldValue('nameOfRole', value);
    };


    return (
        <form className={'row mx-3'} onSubmit={formik.handleSubmit}>

            <div className={'col-md-12'}>
                <div className={'row g-4'}>
                    <div className='text-muted h5 fw-bolder mt-1'>{`1. Alegeti tipul de cont`}</div>
                    {TYPE_ACCOUNTS.filter((typeAccount) =>
                        searchParams.get('role') ? typeAccount.valueType === searchParams.get('role') : true).map((typeAccount, index) => (
                        <div key={index} className={'col-md-12'}>
                            <div className='flex-grow-1 d-flex justify-content-between'>
                                <div className='w-100'>
                                    <div className='row'>
                                        <div className='col-auto'>
                                            <div className="form-check">
                                                <input
                                                    id={typeAccount.valueType}
                                                    style={{width: 15, height: 15}}
                                                    className="form-check-input"
                                                    type="radio"
                                                    value={typeAccount.valueType}
                                                    checked={selectedAccountType === typeAccount.valueType}
                                                    onChange={() => handleInput(typeAccount.valueType)}

                                                />
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <div className='d-flex align-items-center'>
                                                <div className='fw-bold fs-6 me-2'>
                                                    {`${typeAccount.typeAccount}`}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                    }
                    {formik.errors.nameOfRole && (<span className={'text-danger'}>{formik.errors.nameOfRole}</span>)}
                    <div className='text-muted h5 fw-bolder mt-5'>{`2. Detalii cont`}</div>
                    <div className={'col-md-6'}>
                        <FormGroup
                            id={'lastName'}
                            label={'Nume de familie'}>
                            <Input
                                type={'text'}
                                placeholder={'Ex: Popescu'}
                                value={formik.values.lastName}
                                isTouched={formik.touched.lastName}
                                invalidFeedback={formik.errors.lastName}
                                isValid={formik.isValid}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}/>
                        </FormGroup>
                    </div>
                    <div className={'col-md-6'}>
                        <FormGroup
                            id={'firstName'}
                            label={'Prenume'}>
                            <Input
                                type={'text'}
                                placeholder={'Ex: Alexandru'}
                                value={formik.values.firstName}
                                isTouched={formik.touched.firstName}
                                invalidFeedback={formik.errors.firstName}
                                isValid={formik.isValid}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}/>
                        </FormGroup>
                    </div>
                    <div className={'col-md-6'}>
                        <FormGroup
                            id={'email'}
                            label={'Adresa de email'}>
                            <Input
                                type={'email'}
                                placeholder={'Introduceti o adresa de email'}
                                value={formik.values.email}
                                isTouched={formik.touched.email}
                                invalidFeedback={formik.errors.email}
                                isValid={formik.isValid}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}/>
                        </FormGroup>
                    </div>
                    <div className={'col-md-6'}>
                        <FormGroup
                            id={'phoneNumber'}
                            label={'Numar de telefon'}>
                            <Input
                                type={'tel'}
                                placeholder={'Ex: 0765553333'}
                                value={formik.values.phoneNumber}
                                isTouched={formik.touched.phoneNumber}
                                invalidFeedback={formik.errors.phoneNumber}
                                isValid={formik.isValid}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}/>
                        </FormGroup>
                    </div>
                    <div className={'col-md-6'}>
                        <FormGroup
                            id={'dateOfBirth'}
                            label={'Data nasterii'}>
                            <Input
                                type={'date'}
                                value={formik.values.dateOfBirth}
                                isTouched={formik.touched.dateOfBirth}
                                invalidFeedback={formik.errors.dateOfBirth}
                                isValid={formik.isValid}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}/>
                        </FormGroup>
                    </div>
                    <div className={'col-md-6'}>
                        <FormGroup
                            id={'cnp'}
                            label={'Cod numeric personal'}>
                            <Input
                                type={'text'}
                                placeholder={'Introduceti CNP'}
                                value={formik.values.cnp}
                                isTouched={formik.touched.cnp}
                                invalidFeedback={formik.errors.cnp}
                                isValid={formik.isValid}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}/>
                        </FormGroup>
                    </div>

                    <div className={'col-md-6'}>
                        <FormGroup
                            id={'password'}
                            label={'Parola'}>
                            <Input
                                type={'password'}
                                placeholder={'Minim 8 caractere'}
                                value={formik.values.password}
                                isTouched={formik.touched.password}
                                invalidFeedback={formik.errors.password}
                                isValid={formik.isValid}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}/>
                        </FormGroup>
                    </div>
                    <div className={'col-md-6'}>
                        <FormGroup
                            id={'confirmPassword'}
                            label={'Confirmare parola'}>
                            <Input
                                type={'password'}
                                placeholder={'Minim 8 caractere'}
                                value={formik.values.confirmPassword}
                                isTouched={formik.touched.confirmPassword}
                                invalidFeedback={formik.errors.confirmPassword}
                                isValid={formik.isValid}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}/>
                        </FormGroup>
                    </div>
                    <div className='text-muted h5 fw-bolder mt-5'>{`3. Incarca fotografie de profil - 1Mb max `}</div>
                    {formik.errors.profileImage && formik.touched.profileImage ? (
                        <span className={'text-danger fw-bold'}>{`${formik.errors.profileImage}`}</span>) : ''}

                    <div className={'col-md-6 d-flex justify-content-center'}>
                        <ReactImageUploading multiple={false} acceptType={['png', 'jpg', 'jpeg']} maxFileSize={1048576} value={images}
                                             onChange={uploadImage} maxNumber={1}>
                            {({
                                  imageList,
                                  onImageUpload,
                                  onImageRemoveAll,
                                  onImageUpdate,
                                  onImageRemove,
                                  isDragging,
                                  dragProps,
                                  errors
                              }) => (
                                <div>
                                    {imageList.length == 0 && !formik.values.profileImage && (
                                        <Card style={{border: '2px dashed grey', borderRadius: 0,}}
                                              className={'shadow-none'}>
                                            <CardBody>
                                                <div className={'row'}>
                                                    <img
                                                        onClick={onImageUpload}
                                                        {...dragProps}
                                                        style={{cursor: 'pointer'}}
                                                        src={'../assets/upload_image.svg'}
                                                        alt={'upload image'} />
                                                </div>
                                            </CardBody>
                                        </Card>)}

                                    {imageList.map((image, index) => (
                                        <div style={{alignItems: 'center'}} key={index}>
                                            <Avatar src={formik.values.profileImage} sx={{width: 200, height: 200}}/>
                                            <div className="d-flex">
                                                <Button
                                                    onClick={() => onImageUpdate(index)}>Modifica</Button>
                                                <Button onClick={() => {
                                                    onImageRemove(index);
                                                    formik.setFieldValue('profileImage', '');
                                                }}>Sterge</Button>
                                            </div>
                                        </div>
                                    ))}
                                    {errors && (
                                        <div>
                                            {errors.maxFileSize && (<span className={'text-danger'}>
                                                Fisierul selectat este prea mare. Dimensiune fisier maxima acceptata: 1Mb
                                            </span>)}
                                            {errors.acceptType && (<span className={'text-danger'}>
                                                Fisiere acceptate: .png, .jpg, .jpeg
                                            </span>)}
                                        </div>
                                    )}
                                </div>
                            )}
                        </ReactImageUploading>
                    </div>
                    <div className={'col-md-12'}>
                            <div className='text-muted h5 fw-bolder mt-5'>{`4. Incarca C.I - 4 Mb max`}</div>
                            {formik.errors.identityCard && formik.touched.identityCard ? (
                                <span className={'text-danger fw-bold'}>{`${formik.errors.identityCard}`}</span>) : ''}
                            <div className='col-md-12'>
                                <p style={{textAlign: 'justify'}} className='lead text-muted'>
                                    Incarcati o fotografie clara a cartii de identitate si usor de vizualizat.
                                    In cazul in care cerintele nu sunt indeplinite, contul dumneavoastra nu va fi activat.
                                </p>
                            </div>
                        <div className={'col-md-6 d-flex justify-content-center'}>
                            <ReactImageUploading multiple={false} acceptType={['png', 'jpg', 'jpeg']} maxFileSize={4194304} value={imagesCI}
                                                 onChange={uploadCI} maxNumber={1}>
                                {({
                                      imageList,
                                      onImageUpload,
                                      onImageRemoveAll,
                                      onImageUpdate,
                                      onImageRemove,
                                      isDragging,
                                      dragProps,
                                      errors
                                  }) => (
                                    <div>
                                        {imageList.length == 0 && !formik.values.identityCard && (
                                            <Card style={{border: '2px dashed grey', borderRadius: 0,}}
                                                  className={'shadow-none'}>
                                                <CardBody>
                                                    <div className={'row'}>
                                                        <img
                                                            onClick={onImageUpload}
                                                            {...dragProps}
                                                            style={{cursor: 'pointer'}}
                                                            src={'../assets/upload_image.svg'}
                                                            alt={'upload image'} />
                                                    </div>
                                                </CardBody>
                                            </Card>)}

                                        {imageList.map((image, index) => (
                                            <div className={'col-md-12'} style={{alignItems: 'center'}} key={index}>
                                                <img
                                                    src={formik.values.identityCard}
                                                    style={{width: '100%', borderRadius: 8}}/>
                                                <div className="d-flex">
                                                    <Button
                                                        onClick={() => onImageUpdate(index)}>Modifica</Button>
                                                    <Button onClick={() => {
                                                        onImageRemove(index);
                                                        formik.setFieldValue('identityCard', '');
                                                    }}>Sterge</Button>
                                                </div>
                                            </div>
                                        ))}
                                        {errors && (
                                            <div>
                                                {errors.maxFileSize && (<span className={'text-danger'}>
                                                Fisierul selectat este prea mare. Dimensiune fisier maxima acceptata: 4Mb
                                            </span>)}
                                                {errors.acceptType && (<span className={'text-danger'}>
                                                Fisiere acceptate: .png, .jpg, .jpeg
                                            </span>)}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </ReactImageUploading>
                        </div>
                        </div>
                </div>
            </div>
            <CardFooter className={'d-flex justify-content-center flex-row'}>
                <Button type={'submit'} isDisable={formik.isSubmitting}
                        color='info'
                        icon='Save'>
                    {isLoading && (
                        <Spinner color={'light'} isSmall inButton isGrow/>
                    )}
                    {isLoading ? 'Se salveaza, asteptati...' : 'Inregistreaza cont'}
                </Button>
            </CardFooter>
        </form>
    )
}

export default RegisterForm;

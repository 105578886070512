import {createSlice} from "@reduxjs/toolkit";

interface MediaState {
    isUpdated: boolean,
    isAdded: boolean,
}

const initialState: MediaState = {
    isAdded: false,
    isUpdated: false,
}

export const MediaSlice = createSlice({
    name: 'media',
    initialState,
    reducers: {
        updateMedia: (state, action) => {
            state.isUpdated = action.payload;
        },
        addMedia: (state, action) => {
            state.isAdded = action.payload;
        }
    }
});

export const {updateMedia, addMedia} = MediaSlice.actions;
export default MediaSlice.reducer;

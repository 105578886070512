import {createSlice} from "@reduxjs/toolkit";

interface CupState {
    isAdded: boolean,
    isUpdated: boolean,
    editions: any,
}

const initialState: CupState = {
    isAdded: false,
    isUpdated: false,
    editions: [],
}

export const CupSlice = createSlice({
    name: 'cup',
    initialState,
    reducers: {
        addCup: (state, action) => {
            state.isAdded = action.payload;
        },
        updateCup: (state, action) => {
            state.isUpdated = action.payload;
        },
        getEditions: (state, action) => {
            state.editions = action.payload;
        }
    }
});

export const {addCup, updateCup, getEditions} = CupSlice.actions;
export default CupSlice.reducer;


export const getNameOfRoleInRo = (nameOfRole?: string) => {
    switch (nameOfRole) {
        case 'Super Admin' :
            return 'Super Admin';
        case 'Admin':
            return 'Admin';
        case 'Manager':
            return 'Manager';
        case 'Prime Organizer':
            return 'Organizatori principal'
        case 'Organizer':
            return 'Organizator';
        case 'Referee':
            return 'Arbitru';
        case 'Chronicle':
            return 'Cronicar';
        case 'Social Media':
            return 'Social Media';
        case 'Photo Video':
            return 'Foto Video';
        case 'Observer':
            return 'Observator';
        case 'Delegate':
            return 'Delegat';
        case 'Player':
            return 'Jucator';
        default:
            return 'Nu exista rol';


    }
}


import React, {FC} from "react";
import classNames from "classnames";

import {Link} from "react-router-dom";
import {demoPages} from "../../../menu";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";

import Card, {CardBody, CardHeader,} from '../../../components/bootstrap/Card';
import Button from "../../../components/bootstrap/Button";
import RegisterForm from "../../../components/auth/RegisterForm";
import Logo from "../../../components/Logo";
import Page from "../../../layout/Page/Page";


const Register: FC<any> = () => {

    const user = JSON.parse(`${localStorage.getItem('user')}`);


    return (
        <>
            {!user?.token && (<PageWrapper
                isProtected={false}
                title={'Inregistrare'}
                background={true}
                className={classNames({'bg-dark': true})}
            >
                <Page className={'p-0'}>
                    <div style={{marginTop: 20}} className={'row h-100 align-items-center justify-content-center'}>
                        <div className={'col-xl-6 col-lg-6 col-md-8 shadow-3d-container'}>
                            <Card stretch={'full'} className={'shadow-3d-dark'}>
                                <CardBody>
                                    <div className='text-center my-2'>
                                        <Logo width={400} height={150}/>
                                    </div>
                                    <div
                                        className={classNames('rounded-3 bg-l10-dark',)}>
                                        <div className='row row-cols-2 g-3 pb-3 px-3 mt-0'>
                                            <div className='col'>
                                                <Button
                                                    color={'dark'}
                                                    isLight={false}
                                                    icon={'AppRegistration'}
                                                    className='rounded-1 w-100'
                                                    size='lg'>
                                                    {'Inregistrare'}
                                                </Button>
                                            </div>
                                            <div className='col'>
                                                <Link to={'/auth-pages/login'}>
                                                    <Button
                                                        color={'dark'}
                                                        isLight={true}
                                                        className='rounded-1 w-100'
                                                        size='lg'>
                                                        {'Autentificare'}
                                                    </Button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-center h4 fw-bold mt-1'>{`Inregistrare cont aplicatie`}</div>
                                    <div className='text-center h6 text-muted mb-5'>{`Completati formularul`}</div>
                                    <RegisterForm/>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </Page>
            </PageWrapper>)}
        </>
    );
};


export default Register;

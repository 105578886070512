import axios from 'axios';
import {API_AUTH} from "../utils/restApi";
import {demoPages} from "../menu";


const jwtInstance = axios.create({
    baseURL: process.env.REACT_APP_ENDPOINT,
    headers: {
        'Content-Type': 'application/json'
    }
});


jwtInstance.interceptors.request.use(
    (config: any) => {
        const token = JSON.parse(`${localStorage.getItem('user')}`)?.token;
        const controller = new AbortController();
        if (token) {
            // @ts-ignore
            config.headers['Authorization'] = 'Bearer ' + token;
        }else if(!Object.values(API_AUTH).includes(config.url as string)){
            controller.abort();
        }

        return {
            ...config,
            signal: controller.signal
        };
    },

    (error: any) => {
        return Promise.reject(error);
    }
);

jwtInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (err) => {
        const originalConfig = err.config;

        if (originalConfig && originalConfig.url !== API_AUTH.login && err.response && JSON.parse(`${localStorage.getItem('user')}`)) {
            //Access Token was expired
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;
                try {
                    const refreshTokenResponse = await jwtInstance.post(API_AUTH.refreshToken,
                        { refreshToken: JSON.parse(`${localStorage.getItem('user')}`)?.refreshToken })
                        .catch((error) => {
                            if (error.response.data.statusCode === 403) {
                                setTimeout(() => {
                                    localStorage.clear();
                                    window.location.assign(`/${demoPages.login.path}`)
                                }, 100);
                            }
                        });

                    const {token} = refreshTokenResponse?.data;
                    updateToken(token);
                    return jwtInstance(originalConfig);

                } catch (_error) {
                    return Promise.reject(_error);
                }
            }
        }


        return Promise.reject(err);
    }
);

const updateToken = (newToken: any) => {
    // @ts-ignore
    let prevData = JSON.parse(localStorage.getItem('user') ? localStorage.getItem('user') : {});
    prevData.token = newToken;
    localStorage.setItem('user', JSON.stringify(prevData));
};


export default jwtInstance;

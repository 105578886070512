import React from 'react';
import Header, {HeaderLeft, HeaderRight} from '../../../layout/Header/Header';
import useDarkMode from "../../../hooks/useDarkMode";
import User from "../../../layout/User/User";

const DefaultHeader = () => {
    const {darkModeStatus} = useDarkMode();
    const user = JSON.parse(`${localStorage.getItem('user')}`);
    return (
        <>
            {user && (
                <Header>
                    <HeaderLeft>
                        <div style={{fontSize: '16px', fontWeight: 500}}
                             className={darkModeStatus ? 'text-light' : 'text-dark'}>
                            {`Autentificat `}
                        </div>
                        <div className='small text-muted'>
                            {user?.email}
                        </div>
                    </HeaderLeft>
                    <HeaderRight>
                        <User isDefaultHeader={true}/>
                    </HeaderRight>
                </Header>
            )}
        </>
    );
};

export default DefaultHeader;

import {createSlice} from "@reduxjs/toolkit";
interface AwardState {
    isAdded: boolean,
    isUpdated: boolean,
}

const initialState: AwardState = {
    isAdded: false,
    isUpdated: false
}

export const AwardSlice = createSlice({
    name: 'award',
    initialState,
    reducers: {
        addAward: (state, action) => {
            state.isAdded = action.payload;
        },
        updateAward: (state, action) => {
            state.isUpdated = action.payload;
        }
    }
});

export const {addAward, updateAward} = AwardSlice.actions;
export default AwardSlice.reducer;

import {createSlice} from "@reduxjs/toolkit";
interface FaqState {
    isAdded: boolean,
    isUpdated: boolean
}

const initialState: FaqState = {
    isAdded: false,
    isUpdated: false,
}

export const FaqSlice = createSlice({
    name: 'faq',
    initialState,
    reducers: {
        addFaq: (state, action) => {
            state.isAdded = action.payload;
        },
        updateFaq: (state, action) => {
            state.isUpdated = action.payload;
        }
    }
});

export const {addFaq, updateFaq} = FaqSlice.actions;
export default FaqSlice.reducer;

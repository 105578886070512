import {createSlice} from "@reduxjs/toolkit";

interface InvitationState {
    isApproved: boolean,
}

const initialState: InvitationState = {
    isApproved: false,
}

export const InvitationSlice = createSlice({
    name: 'invitation',
    initialState,
    reducers: {
        approvedPlayer: (state, action) => {
            state.isApproved = action.payload;
        }
    }
});

export const {approvedPlayer} = InvitationSlice.actions;
export default InvitationSlice.reducer;

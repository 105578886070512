import React, {FC, useCallback, useState} from "react";
import classNames from "classnames";

import {Link, useNavigate} from "react-router-dom";
import {demoPages} from "../../../menu";

import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import Page from "../../../layout/Page/Page";
import Card, {CardBody, CardHeader} from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import Logo from "../../../components/Logo";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";

import jwtInstance from "../../../guards/jwt.interceptor";
import {API_AUTH} from "../../../utils/restApi";
import {errorResponse, successResponse} from "../../../utils/getResponse";



const VerifyAccount: FC<any> = () => {

    const user = JSON.parse(`${localStorage.getItem('user')}`);
    const navigate = useNavigate();
    const [activationCodeEmail, setActivationCodeEmail] = useState('');
    const [activationCodeSMS, setActivationCodeSMS] = useState('');

    const handleActivationCodeEmail = useCallback((event: any) => {
        setActivationCodeEmail(event.target.value);
    }, [activationCodeEmail]);

    const handleActivationCodeSMS = useCallback((event: any) => {
        setActivationCodeSMS(event.target.value);
    }, [activationCodeSMS]);

    const verifyAccount = async () => {
        await jwtInstance.get(API_AUTH.verify, {params: {emailToken: activationCodeEmail, smsToken: activationCodeSMS}})
            .then(response => {
                if (response.status === 200) {
                    successResponse(response, 'Verificare cont', 'success');
                    navigate({
                        pathname: `/${demoPages.login.path}`,
                    });
                }
            }).catch(error => {
                errorResponse(error, 'Eroare verificare cont', 'danger');
            })
    };


    return (
        <>
            {!user?.token && (<PageWrapper isProtected={false} title={'Verificare cont'} background={false} isVerify={true}
                                    className={classNames({'bg-success': true})}>
                <Page className={'p-0'}>
                    <div className='row h-100 align-items-center justify-content-center'>
                        <div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
                            <Card className={'shadow-3d-dark'} data-tour={'login-player-page'}>
                                <CardHeader className={'justify-content-end'}>
                                    <Link to={`/${demoPages.login.path}`}>
                                        <Button
                                            icon={'ArrowBackIos'}
                                            isLight={false}>
                                            {'Inapoi'}
                                        </Button>
                                    </Link>
                                </CardHeader>
                                <CardBody>
                                    <div className='text-center my-2'>
                                        <Logo width={400} height={150}/>
                                    </div>
                                    <div
                                        className={classNames('rounded-3', {
                                            'bg-l10-dark': true,
                                            'bg-dark': false,
                                        })}>
                                        <div className='row row-cols-2 g-3 pb-3 px-3 mt-0 justify-content-center'>
                                            <div className='col-12'>
                                                <Button
                                                    color={'dark'}
                                                    icon={'Apps'}
                                                    isLight={false}
                                                    style={{cursor: 'initial'}}
                                                    className='rounded-1 w-100'
                                                    size='lg'>
                                                    {'Verificare cont'}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                    <>
                                        <div className='text-center h5 fw-bold mt-5'>Introduceti codul primit</div>

                                        <div className={'text-center'}>
                                            <span className={'text-muted'}>{'Email'}</span>
                                        </div>

                                    </>

                                    <div className={'row g-4 mb-2'}>
                                        <div className={'col-12'}>
                                            <FormGroup
                                                id='codeFromEmail'
                                                isFloating
                                                label='Cod primit pe email'>

                                                <input
                                                    style={{textTransform: 'uppercase'}}
                                                    type={'text'}
                                                    autoFocus={false}
                                                    className={'form-control'}
                                                    onChange={(event: any) => handleActivationCodeEmail(event)}
                                                />
                                            </FormGroup>
                                        </div>
                                    </div>

                                    <div className={'row g-4 justify-content-center'}>
                                        <div className={'col-6'}>
                                            <Button
                                                color='danger'
                                                onClick={() => verifyAccount()}
                                                className='w-100 py-3'
                                                icon={'Send'}
                                                type={'submit'}>
                                                {'Trimite'}
                                            </Button>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </Page>
            </PageWrapper>)}
        </>
    );
};

export default VerifyAccount;

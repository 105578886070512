import React, {ReactNode, useContext, useState} from 'react';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
import Brand from '../../../layout/Brand/Brand';
import Navigation, {NavigationLine} from '../../../layout/Navigation/Navigation';
import User from '../../../layout/User/User';
import {
    administrationAccount,
    administrationSite,
    appManagement,
    dashboardMenu,
    documentsPlayer,
    matchManagements, matchStatisticsManage
} from '../../../menu';
import ThemeContext from '../../../contexts/themeContext';
import Icon from '../../../components/icon/Icon';
import Aside, {AsideBody, AsideFoot, AsideHead} from '../../../layout/Aside/Aside';


const DefaultAside = () => {

    const user = JSON.parse(`${localStorage.getItem('user')}`);
    const {asideStatus, setAsideStatus} = useContext(ThemeContext);
    const [doc, setDoc] = useState(false);
    const {t} = useTranslation(['translation', 'menu']);

    return (
        <>
            {user?.token && (<Aside>
                <AsideHead>
                    <Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus}/>
                </AsideHead>
                <AsideBody>
                    <>
                        <Navigation menu={dashboardMenu} id='aside-dashboard'/>

                        {(user?.roles[0] === 'ROLE_PLAYER' || user?.roles[0] === 'ROLE_DELEGATE') && (
                            <Navigation menu={documentsPlayer} id='aside-documents-player'/>
                        )}


                        {(user?.roles[0] === 'ROLE_ADMIN' || user?.roles[0] === 'ROLE_SUPER_ADMIN' || user?.roles[0] === 'ROLE_OBSERVER') && (
                            <Navigation menu={matchManagements} id='match-management'/>
                        )}

                        {(user?.roles[0] === 'ROLE_ADMIN' || user?.roles[0] === 'ROLE_SUPER_ADMIN' || user?.roles[0] === 'ROLE_ORGANIZER') && (
                            <Navigation menu={matchStatisticsManage} id='match-statistics-management'/>
                        )}
                    </>

                    {!doc && (
                        <>
                            {(user?.roles[0] === 'ROLE_ADMIN' || user?.roles[0] === 'ROLE_SUPER_ADMIN') && (

                                <Navigation menu={appManagement} id='app-management'/>

                            )}
                            {user?.roles[0] === 'ROLE_MANAGER' && (
                                <>
                                    <NavigationLine/>
                                    <Navigation menu={administrationAccount} id='administration-account'/>
                                </>
                            )}
                            <NavigationLine/>

                            {(user?.roles[0] === 'ROLE_ADMIN' || user?.roles[0] === 'ROLE_SUPER_ADMIN' || user?.roles[0] === 'ROLE_PHOTO_VIDEO') && (
                                <>
                                    <Navigation menu={administrationSite} id='aside-menu'/>
                                    <NavigationLine/>
                                </>
                            )}


                        </>
                    )}
                    {asideStatus && doc && <div className='p-4'>Documentation</div>}
                </AsideBody>
                <AsideFoot>
                    <User isDefaultHeader={false}/>
                </AsideFoot>
            </Aside>)}
        </>
    );
};

export default DefaultAside;
